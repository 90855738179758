import React, { Component } from "react";
// import eip55 from "eip55";
import AppBtc from "@ledgerhq/hw-app-btc";
import QRCode from "./../QRCode";
import CryptoJS from 'crypto-js';
import { bytesToHex, stringToUTF8Bytes } from './../utils/hex';
import { Base64ToHex, HexToBase64 } from './../utils/converter';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// const delay = ms => new Promise(success => setTimeout(success, ms));

export default class ShowAddressScreen extends Component {
  state = {
    error: null,
    address: null,

    addressType: "bech32",
    path: "84'/0'/0'/0/1", // First address in Ledger BTC App

    message: "",
    messageHash: "",
    messageBytes: [],

    signatureObject: "",
    output: "",
  };

  async componentDidMount() {
    // while (!this.state.address) {
    //   if (this.unmounted) return;
    //   await this.fetchAddress(false);
    //   await delay(500);
    // }
    // this.fetchAddress(true);
  }

  async componentWillUnmount() {
    this.unmounted = true;
  }

  fetchAddress = async verify => {
    const { transport } = this.props;
    const { path, addressType } = this.state;
    // try {
    //   const eth = new AppBtc(transport);
    //   const r = await eth.getAddress(path, verify);
    //   const address = eip55.encode(r.address);
    //   if (this.unmounted) return;
    //   this.setState({ address });
    // } catch (error) {
    //   // in this case, user is likely not on Ethereum app
    //   console.warn("Failed: " + error.message);
    //   if (this.unmounted) return;
    //   this.setState({ error });
    //   return null;
    // }
    try {
      const appBtc = new AppBtc(transport);
      const result = await appBtc.getWalletPublicKey(path, { format: addressType });
      const address = result.bitcoinAddress;
      if (this.unmounted) return;
      this.setState({ address });
    } catch (error) {
      // in this case, user is likely not on Ethereum app
      console.warn("Failed: " + error.message);
      if (this.unmounted) return;
      this.setState({ error });
      return null;
    }
  };

  signMessage = async () => {
    const { transport } = this.props;
    const { path, addressType, message } = this.state;
    try {
      const appBtc = new AppBtc(transport);
      const result = await appBtc.getWalletPublicKey(path, { format: addressType });

      // console.log(crypto.createHash("sha256").update(message).digest("hex"));
      console.log('Message sha256 hash:', CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex));
      const bytes = bytesToHex(stringToUTF8Bytes(message));
      console.log('Message bytes:', bytes);
      const signature = await appBtc.signMessageNew(path, bytes);
      console.log(signature); // This is the same for all of the below, and

      // There is an issue with the small cordinates for points in r or s values.
      // It is bug in Electron implementation that we can fix by appending 0os to the front of the cordinate.
      const prependSignature = (input) => {
        return `${Array(64 + 1).join('0')}${input}`.slice(-64)
      };

      const HEADER_signature = `${(27 + 4 + signature.v).toString(16)}${prependSignature(signature.r)}${(signature.s)}`

      const output = `${HexToBase64(HEADER_signature)}\n\n${Base64ToHex(HexToBase64(HEADER_signature))}`;

      const address = result.bitcoinAddress;
      if (this.unmounted) return;
      this.setState({ address, signatureObject: JSON.stringify(signature, null, 2), output });
    } catch (error) {
      // in this case, user is likely not on Bitcoin app
      console.warn("Failed: " + error.message);
      if (this.unmounted) return;
      this.setState({ error });
      return null;
    }
  }

  handlePathChange = (event) => {
    const path = event.target.value;
    // Update state
    this.setState({ path });
  }

  handleAddressTypeChange = (event) => {
    const addressType = event.target.value;
    console.log(addressType);
    // Update state
    this.setState({ address: null, addressType }, () => {
      this.fetchAddress();
    });
  }

  handleMessageChange = (event) => {
    const message = event.target.value;
    // Get the input, get the hash,
    const messageHash = CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
    console.log('Message sha256 hash:', messageHash);
    const messageBytes = bytesToHex(stringToUTF8Bytes(message));
    console.log('Message bytes:', messageBytes);
    // Update state
    this.setState({ message, messageHash, messageBytes });
  }

  render() {
    const { address, error } = this.state;

    return (
      <div className="ShowAddressScreen">
        <Container>
          <Row>
            <Col className="text-center">
              {!address ? (
                <>
                  <p className="loading">Press "Get address" button to loading your Bitcoin address...</p>
                  {error ? (
                    <p className="error">
                      A problem occurred, make sure to open the Bitcoin application
                      on your Ledger Nano X. (
                      {String((error && error.message) || error)})
                    </p>
                  ) : null}
                </>
              ) : (
                <>
                  <strong>Ledger Live Bitcoin Account ({this.state.path})</strong>
                  <QRCode data={address} size={300} />
                  <strong>{address}</strong>
                </>
              )}
            </Col>
            <Col className="text-center">
              {/* <div className="input-group mb-3">
                <label className="input-group-text" for="inputGroupSelect01">Address type</label>
                <select className="form-select" onChange={this.handleAddressTypeChange} id="inputGroupSelect01">
                  <option value="bech32" selected={(this.state.addressType === "bech32")}>bech32</option>
                  <option value="p2sh" selected={(this.state.addressType === "p2sh")}>p2sh</option>
                  <option value="legacy" selected={(this.state.addressType === "legacy")}>legacy</option>
                </select>
              </div> */}
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <label className="input-group-text" for="inputGroupSelect01">Address type</label>
                </div>
                <select className="custom-select" onChange={this.handleAddressTypeChange} id="inputGroupSelect01">
                <option value="bech32" selected={(this.state.addressType === "bech32")}>bech32</option>
                  <option value="p2sh" selected={(this.state.addressType === "p2sh")}>p2sh</option>
                  <option value="legacy" selected={(this.state.addressType === "legacy")}>legacy</option>
                </select>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">Path</span>
                </div>
                <input type="text" className="form-control" onChange={this.handlePathChange} value={this.state.path} placeholder="Path" aria-label="Path" aria-describedby="basic-addon1"/>
                <div className="input-group-append">
                  <Button onClick={this.fetchAddress}>
                    Get address
                  </Button>
                </div>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">Address</span>
                </div>
                <input type="text" className="form-control" disabled="disabled" value={(this.state.address === null) ? "" : this.state.address} placeholder="..." aria-label="Path" aria-describedby="basic-addon1"/>
              </div>
              <hr/>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Message</span>
                </div>
                <textarea className="form-control" onChange={this.handleMessageChange} value={this.state.message}  aria-label="Message"></textarea>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">Message hash SHA256</span>
                </div>
                <input type="text" className="form-control" value={this.state.messageHash} placeholder="Hash" aria-label="Username" aria-describedby="basic-addon1"/>
              </div>
              <div className="input-group">
                <Button onClick={this.signMessage}>
                  Sign the message
                </Button>
              </div>
              <hr />
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Signature V R S values:</span>
                </div>
                <textarea className="form-control" value={this.state.signatureObject} aria-label="Output"></textarea>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Output</span>
                </div>
                <textarea className="form-control" value={this.state.output} aria-label="Output"></textarea>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
