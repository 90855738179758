import React, { Component } from "react";
import { ToastContainer } from 'react-toastify';

import DeviceSelectionScreen from "./views/DeviceSelectionScreen";
import ShowAddressScreen from "./views/ShowAddressScreen";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";

class App extends Component {
  state = {
    transport: null
  };

  onSelectDevice = transport => {
    window.ledgerTransport = transport;
    transport.on("disconnect", () => {
      this.setState({ transport: null });
    });
    this.setState({ transport });
  };

  render() {
    const { transport } = this.state;
    return (
      <div className="App">
        {!transport ? (
          <DeviceSelectionScreen onSelectDevice={this.onSelectDevice} />
        ) : (
          <ShowAddressScreen transport={transport} />
        )}

        <ToastContainer />
      </div>
    );
  }
}

export default App;
