import React, { Component } from "react";
import TransportWebBLE from "@ledgerhq/hw-transport-web-ble";
import TransportWebUSB from "@ledgerhq/hw-transport-webusb";

import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class DeviceSelectionScreen extends Component {
  state = {
    devices: []
  };

  createBLE = async () => {
    // toast.success("Using BLE transport!", {
    //   position: toast.POSITION.TOP_RIGHT
    // });
    const transport = await TransportWebBLE.create()
      .catch((error) => {
        toast.error(error.toString(), {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      });
    
    if (typeof transport !== 'undefined') {
      this.props.onSelectDevice(transport);
    }
  };

  createUSB = async () => {
    // toast.success("Using USB transport!", {
    //   position: toast.POSITION.TOP_RIGHT
    // });
    const transport = await TransportWebUSB.create()
      .catch((error) => {
        toast.error(error.toString(), {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      });
    
    if (typeof transport !== 'undefined') {
      this.props.onSelectDevice(transport);
    }
  };

  render() {
    return (
      <div className="DeviceSelectionScreen">
        <Container>
          <Row>
            <Col>
              <h3 className="mb-3">
                Power up your Ledger Nano X and enter your pin before continuing...
              </h3>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button onClick={this.createBLE}>
                Connect with Bluetooth
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={this.createUSB}>
                Connect with USB
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
